/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    pre: "pre",
    code: "code",
    blockquote: "blockquote",
    h3: "h3",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "modular-exponentiation-in-rust",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modular-exponentiation-in-rust",
    "aria-label": "modular exponentiation in rust permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modular Exponentiation in Rust"), "\n", React.createElement(_components.p, null, "And the program to find Power of a number using Modular Arithmetic in Rust Language."), "\n", React.createElement(_components.h2, {
    id: "what-is-modular-exponentiation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-modular-exponentiation",
    "aria-label": "what is modular exponentiation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Modular Exponentiation"), "\n", React.createElement(_components.p, null, "Many times, we have to compute exponents of a given number for various purposes. But it is notable that overflow may occur for large values. Largest number that we can store with numerical data type in rust is ", React.createElement(_components.strong, null, React.createElement("code", null, "2", React.createElement("sup", null, "128"))), ", and is 2", React.createElement("sup", null, "64"), " in C / C++."), "\n", React.createElement(_components.p, null, "Now, suppose, in some question, we have to find ", React.createElement(_components.strong, null, React.createElement("code", null, "2", React.createElement("sup", null, "1000"))), " modulo 1000000007. If we try to first compute ", React.createElement(_components.strong, null, React.createElement("code", null, "2", React.createElement("sup", null, "1000"))), " and then find modulo, rust will throw overflow error."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python"
  }, "thread 'main' panicked at 'attempt to multiply with overflow', src/iterative.rs:10:9\n")), "\n", React.createElement(_components.h2, {
    id: "problem-statement",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#problem-statement",
    "aria-label": "problem statement permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Problem statement"), "\n", React.createElement(_components.p, null, "Given three numbers n, x and p, compute n", React.createElement("sup", null, "x"), "  modulo p."), "\n", React.createElement(_components.h2, {
    id: "naive-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#naive-approach",
    "aria-label": "naive approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Naive Approach"), "\n", React.createElement(_components.p, null, "Simplest solution to this would be to take 1, and multiply it with n, x times, and find modulo p each time. From ", React.createElement(_components.a, {
    href: "/number-theory/modular-multiplication/#2-multiplication-property"
  }, "Modular Multiplication"), ", we already know that"), "\n", React.createElement("center", null, " ", React.createElement("b", null, "(a x b) mod m = ((a mod m) x (b mod m)) mod m"), " "), "\n", React.createElement(_components.p, null, "But this will be done in ", React.createElement(_components.strong, null, "O( x )"), " or ", React.createElement(_components.strong, null, "Linear time complexity.")), "\n", React.createElement(_components.p, null, "Here's the code for this approach"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn modular_exponent(n:usize , x:usize , p:usize) -> usize{\n    // Initialize ans = 1\n    let mut ans = 1;\n\n    // Multiply ans with n, x times, ans modulo\n    for _ in 0..x {\n        ans *= n;\n        ans%=p;\n    }\n\n    // Return ans\n    return ans;\n}\n")), "\n", React.createElement(_components.p, null, "With Driver Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn modular_exponent(n:usize , x:usize , p:usize) -> usize{\n    // Initialize ans = 1\n    let mut ans = 1;\n\n    // Multiply ans with n, x times, ans modulo\n    for _ in 0..x {\n        ans *= n;\n        ans%=p;\n    }\n\n    // Return ans\n    return ans;\n}\n\n// Driver Code\n\nuse std::io;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n\nfn main() {\n    let n = take_int();\n    let x = take_int();\n    let p = take_int();\n\n    println!(\"{}\", modular_exponent(n, x, p));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "2 ", React.createElement("br"), "\n100000 ", React.createElement("br"), "\n1000000007 ", React.createElement("br")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "607723520"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( x )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "efficient-divide-and-conquer-solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#efficient-divide-and-conquer-solution",
    "aria-label": "efficient divide and conquer solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Efficient Divide and Conquer solution"), "\n", React.createElement(_components.p, null, "We can find the modular exponentiation in logarithmic time complexity, using  Divide and Conqueror approach."), "\n", React.createElement(_components.h3, {
    id: "approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#approach",
    "aria-label": "approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Approach"), "\n", React.createElement(_components.p, null, "We know that mathematically,"), "\n", React.createElement("center", null, " ", React.createElement("b", null, " n", React.createElement("sup", null, "a.b"), " = (n", React.createElement("sup", null, "a"), " )", React.createElement("sup", null, " b "), " "), " "), "\n", React.createElement(_components.p, null, "So, let's suppose we have to find n", React.createElement("sup", null, "x"), " and x = 2.y, so, we can find ( n ", React.createElement("sup", null, " 2 "), " ) ", React.createElement("sup", null, " y "), ". ", React.createElement("br"), " ", React.createElement(_components.strong, null, "In y + 1 steps or x/2 + 1 steps")), "\n", React.createElement(_components.p, null, React.createElement("center", null, " ", React.createElement("b", null, " n", React.createElement("sup", null, "2.y"), " = (n", React.createElement("sup", null, "2"), " )", React.createElement("sup", null, " y "), " "), " "), "\nHence, we multiply n by itself, if x is even."), "\n", React.createElement(_components.p, null, "If, on the other hand, x is odd number ", React.createElement(_components.strong, null, "it is guaranteed that x-1 will be even number"), ", hence, we multiply answer by n, if x is odd, and reduce x by 1."), "\n", React.createElement("hr"), "\n", React.createElement(_components.h3, {
    id: "algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm",
    "aria-label": "algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "If x <= 0, return 1."), "\n", React.createElement(_components.li, null, "If x is 1, return (answer * n) % p"), "\n", React.createElement(_components.li, null, "If x  > 1 and even, change n to n", React.createElement("sup", null, "2"), ", change x to x/2, and go to step 2"), "\n", React.createElement(_components.li, null, "If X > 1 and odd, multiply answer by n and store answer modulo p, and reduce x to x-1 and go to step 2."), "\n"), "\n", React.createElement(_components.h2, {
    id: "program-for-modular-exponentiation-in-rust",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#program-for-modular-exponentiation-in-rust",
    "aria-label": "program for modular exponentiation in rust permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Program for Modular Exponentiation in Rust"), "\n", React.createElement(_components.p, null, "Implementation of above algorithm is written below"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn modular_exponent(mut n:usize ,mut x:usize , p:usize) -> usize{\n    // Initialize ans = 1\n    let mut ans = 1;\n\n    // x is 0, return 1\n    if x<=0 {\n        return 1;\n    }\n\n    // use loop statement in rust for infinite loop\n    loop {\n        // Step 2. If x is 1, return (answer * n) % p\n        if x==1 {\n            return (ans * n) % p;\n        }\n\n        // Step 3. If x > 1 and even, change n to n^2, change x to x/2, and go to step 2\n\n        // for checking if x is even, we check the LSB. is 0 or 1\n        // Alternatively, we can also check x%2, but this is more efficient\n        if x&1==0 {\n            n=( n * n ) % p;\n            x>>=1; // or x = x/2\n            continue;\n        }\n\n        // Step 4. If X > 1 and odd, multiply answer by n and store answer modulo p,\n        // and reduce x to x-1 and go to step 2.\n        else {\n            ans = (ans*n) % p;\n            x-=1;\n        }\n    }\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "2 ", React.createElement("br"), "\n100000 ", React.createElement("br"), "\n1000000007 ", React.createElement("br")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "607723520"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( log ", React.createElement("sub", null, " 2 "), " x)"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Modular exponentiation is very frequently used concept in competitive programming for computing the answer.\nIn this article, we made a program for modular exponentiation in rust in logarithmic time complexity instead of linear time complexity using Divide and Conquer approach."), "\n", React.createElement(_components.p, null, "Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn modular_exponent(mut n:usize ,mut x:usize , p:usize) -> usize{\n    let mut ans = 1;\n    if x<=0 { return 1; }\n    loop {\n        if x==1 { return (ans * n) % p; }\n        if x&1==0 { n=( n * n ) % p; x>>=1;continue; }\n        else { ans = (ans*n) % p;x-=1; }\n    }\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Modular Exponentiation ( Power of a number using Modular Arithmetic ) - Rust Programming",
    description: "Modular exponentiation is very frequently used concept in competitive programming. Here is the program for modular exponentiation in rust in logarithmic time complexity instead of linear using Divide and Conquer"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
